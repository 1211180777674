body {
  overflow-x: hidden;
}

.movie-list-container {
  display: flex;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 1.25rem;
}

.media-link {
  color: inherit;
  text-decoration: none;
  padding: .625rem;
  margin: .313rem;
  font-size: 1rem;
}

.media-link:hover {
  font-weight: bold;
  transform: scale(1.05);
}

@media (max-width: 62.5rem) {
.movie-list-container {
  display: grid;
    max-width: 50%;
    margin: 0 auto;
  }
}

.homepage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.homepage-link {
  padding: .625rem 1.25rem;
  margin: .313rem .125rem;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: .25rem;
  cursor: pointer;
}

.homepage-content {
  text-align: center;
}

.homepage-title {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: .625rem;
}

.homepage-description {
  font-size: 1.2em;
  color: #555;
}

.navbar {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  background-color: #fafafa;
  padding: .313rem;
  box-shadow: 0 .125rem .313rem rgba(0, 0, 0, 0.1);
}

.navbar:hover {
  box-shadow: 0 .188rem .438rem rgba(0, 0, 0, 0.1);
}

.movie-search-input {
  width: 20%;
  min-width: 20%;
  height: 1.875rem;
  padding: .625rem;
  border: .063rem solid #ccc;
  border-radius: .25rem;
  margin-right: .625rem;
}

@media (max-width: 62.5rem) {
  .movie-search-input {
    width: 90%;
  }
}

.media-type-button {
  padding: .625rem 1.25rem;
  margin: .313rem .125rem;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: .25rem;
  cursor: pointer;
}

.movie-search-button {
  padding: .625rem 1.25rem;
  margin: .313rem .125rem;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: .25rem;
  cursor: pointer;
}

.tv-search-button {
  padding: .625rem 1.25rem;
  margin: .313rem .125rem;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: .25rem;
  cursor: pointer;
}

.load-more-button {
  position: fixed;
  bottom: 2%;
  left: 2%;
  height: 4.313rem;
  width: 9.375rem;
  padding: .625rem;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: .25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 62.5rem) {
  .load-more-button {
    width: 11.25rem;
    height: 2.5rem;
    font-size: 1.25rem;
    right: 1.875rem;
    left: auto;
  }
}

.movie-search-button:hover {
  background-color: #0056b3;
}

.tv-search-button:hover {
  background-color: #0056b3;
}

.movie-search-button.selected {
  background-color: #004c9c;
}

.tv-search-button.selected {
  background-color: #004c9c;
}

.tv-search-button.selected {
  background-color: #004c9c;
}

.movie-list {
  display: flex;
  flex-wrap: wrap;
}

.movie-card {
  background-color: #ffffff;
  box-shadow: 0 0 .625rem rgba(0, 0, 0, 0.1);
  border-radius: .5rem;
  margin: .625rem;
  padding: .625rem;
  width: 15.625rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.movie-card:hover {
  background-color: #f5f5f5;
  transform: scale(1.02);
}

.movie-card img {
  width: 100%;
  border-radius: .5rem;
}

.movie-details {
  padding: .625rem 0;
}

.nav-bar {
  display: flex;
}

.nav-item {
  padding: .625rem 1.25rem;
  cursor: pointer;
  border: .063rem solid #ccc;
  border-radius: .25rem;
  margin-right: .625rem;
}

.nav-item:hover {
  background-color: #f0f0f0;
}

.active {
  background-color: #007BFF;
  color: white;
  border: none;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-height: 75%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
  border: .063rem solid rgba(0, 0, 0, 0.1);
  border-radius: .625rem;
}

@media only screen and (max-width: 37.5rem) {
  .modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-height: 95%;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.5);
    border: .063rem solid rgba(0, 0, 0, 0.1);
    border-radius: .625rem;
  }
}

.close {
  margin-right: .625rem;
  margin-top: .625rem;
  padding: .625rem 1.25rem;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: .25rem;
  cursor: pointer;
}

@media only screen and (max-width: 37.5rem) {
.modal {
    width: 80%;
  }
}

.modal-content {
  background-color: white;
  width: 100%;
}

.close {
  float: right;
  font-size: 2.25rem;
  font-weight: bold;
  cursor: pointer;
}

.close:hover {
  color: #aaa;
}

.poster-container {
  text-align: center;
}

.poster-container img {
  max-width: 20%;
  max-height: 30%;
  height: auto;
}
